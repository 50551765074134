@font-face {
  font-family: Greycliff-CF;
  src: url(Greycliff-CF-Medium.otf);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  overflow: hidden;
}
